@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

#HW_badge_cont {
  position: absolute !important;
  top: -9px;
}

@media screen and (min-width: 640px) {
  #HW_badge_cont {
    top: 9px;
  }
}

/* use roles with rtl, needs a caption for getByRole('table', { name: '...' }) */
table caption {
  @apply sr-only;
}

.HW_badge.HW_softHidden {
  background: #ffffff !important;
}

/* react-datasheet override */
.data-grid-container .data-grid .cell,
.data-grid-container .data-grid .cell > input {
  height: 24px !important;
  width: 100% !important;
}

/* react-code-blocks */
code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace !important;
}

/* react-date-picker overrides */
.react-datepicker {
  font-family: theme('fontFamily.sans') !important;
}

.react-datepicker__month, .react-datepicker__day,
.react-datepicker__time-list-item,
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: theme('colors.gray.900') !important;
}

.react-datepicker__day--disabled,
.react-datepicker__time-list-item--disabled {
  color: theme('colors.gray.400') !important;
  cursor: not-allowed !important;
}

.react-datepicker__header {
  background-color: theme('colors.gray.100') !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: theme('colors.blue.50') !important;
  color: theme('colors.blue.700') !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-end,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected {
  background-color: theme('colors.blue.700') !important;
  color: white !important;
}
